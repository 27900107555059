import React, { useEffect, useState } from 'react';
import {
  Button,
  Space,
  Table,
  TablePaginationConfig,
} from 'antd';
import {
  TableCell,
  TableTitle
} from './Table';
import {
  MeterProps,
} from './MeterProps';
import {
  AlarmEventType,
  MeterEvent,
  useListLiveMeterEvent,
  EventLevel,

} from '../services/eventApi';
import { formatDurtionStr } from '../utils/converter';


const PAGE_SIZE = 10;

export const OfflineEventLogs: React.FC<MeterProps> = (props) => {
  let [page, setPage] = useState(1);

  const params = {
    meterId: props.meterId,
    pageSize: PAGE_SIZE,
    page,
  }

  const {
    data, isFetching, isSuccess, refetch,
  } = useListLiveMeterEvent(params);
  const [events, setEvents] = useState<MeterEvent[]>([]);

  const formatDuration = (diff: number) => {
    return formatDurtionStr(diff, true);
  };

  const columns = [
    {
      title: () => <TableTitle>Meter No.</TableTitle>,
      render: (event: MeterEvent) => <TableCell>{event.meterno}</TableCell>
    },
    {
      title: () => <TableTitle>Start Time</TableTitle>,
      render: (event: MeterEvent) => <TableCell>{event.eventStartTime.toLocaleString('en-GB')}</TableCell>
    },
    {
      title: () => <TableTitle>End Time</TableTitle>,
      render: (event: MeterEvent) => <TableCell>{event.reportTime.toLocaleString('en-GB')}</TableCell>
    },
    {
      title: () => <TableTitle>Duration</TableTitle>,
      render: (event: MeterEvent) => <TableCell>{formatDuration(event.duration)}</TableCell>
    },
  ];

  useEffect(() => {
    if (!isFetching && isSuccess && data) {
      setEvents(data.objs.map(item => ({
        meterno: props.meterNo,
        eventStartTime: new Date(item.liveTime),
        eventEndTime: new Date(item.onlineTime),
        duration: item.duration,
        eventitemid: 0,
        eventdisc: 'offline',
        pointid: 0,
        reportTime: new Date(item.onlineTime),
        level: EventLevel.Critical,
        alarmEventType: AlarmEventType.All,
        isNew: false,
       })));
    }
  }, [isFetching, isSuccess, data, props.meterNo]);

  return (
    <>
      <Space style={{ height: 35, margin: 0, marginLeft: 10, marginRight: 10, justifyContent: 'flex-end' }}>
          <Button size='small' onClick={refetch}>Reload</Button>
      </Space>
      <div className='widget' style={{ flex: 1, margin: 10 }}>
        <div className='header'>
          <span className='subtitle subtitle--bigger-1'>
            {`Offline Log of Meter No.${props.meterNo}`}
          </span>
        </div>
        <div className='body'>
          <Table
            loading={isFetching}
            style={{ width: '100%', height: '100%' }}
            className="chart"
            dataSource={events}
            columns={columns}
            pagination={{
              position: ['bottomCenter'],
              showSizeChanger: false,
              pageSize: PAGE_SIZE,
              total: data ? data.total : undefined }}
            onChange={(pagination: TablePaginationConfig) => {
              if (pagination.current) {
                setPage(pagination.current)
              }
            }}
            />
        </div>
      </div>
    </>
  );
};
