
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import React, { useEffect, useRef, useState } from 'react';
import { notification, Button, Space } from 'antd';
import { useAppDispatch, useAppSelector } from './hooks';
import {
  Account,
  Widget,
  useSignIn,
  useUpdateWidgets,
  widgetApi,
} from './services/widgetsApi';
import {
  TargetNode,
} from './services/meterDataApi';
import {
  Node,
} from './services/projectApi';
import {
  AlarmEventType,
  EventLevel,
  MeterEventRaw,
  useListEventDict
} from './services/eventApi';
import { setCredentials, resetCredentials } from './slices/authSlices';
import { translate } from './utils/translate';
// import io from 'socket.io-client';
const io = require('socket.io-client');


interface AuthContextType {
  account?: Account;
  signin: (username: string, password: string, callback: (account?: Account) => void) => void;
  signout: (callback: VoidFunction) => void;
  isSigningIn: boolean;
  errorMessage: string;
  isAdmin: boolean;
}

const AuthContext = React.createContext<AuthContextType>(null!);

export const AuthProvider: React.FC = (props) => {
  const accountKey = 'account';
  const dispatch = useAppDispatch();
  const account = useAppSelector(state => state.auth.user);

  const [
    signIn,
    {
      isLoading: isSigningIn,
      reset,
    },
  ] = useSignIn({ fixedCacheKey: 'auth' });

  let signin = (username: string, password: string, callback: (account?: Account) => void) => {

    signIn({ accountid: username.trim(), password: password.trim() }).unwrap()
    .then((account) => {
      dispatch(setCredentials({ user: account, token: account.HTTP_ACCESS_TOKEN }));
      localStorage.setItem(accountKey, JSON.stringify(account));

      // socketRef.current = setupSocketIO();
    })
    .catch((error) => {
      console.log('error');
    })
  };

  let signout = (callback: VoidFunction) => {
    reset();
    dispatch(resetCredentials());
    dispatch(widgetApi.util.resetApiState());
    localStorage.removeItem(accountKey);

    // if (socketRef.current) {
    //   tearDownSocketIO(socketRef.current)
    // }
  };

  let value = {
    account: account?.success === "1" ? account : undefined,
    signin,
    signout,
    isSigningIn,
    errorMessage: account && account?.success !== "1" ? "Login Failed" : "",
    isAdmin: account ? account.isAdmin : false,
  };


  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
}

interface EventContext {
  newNotifCount: number;
  newEvents: Array<MeterEventRaw>;
  clearNewNotifCount: () => void;
}

const EventContext = React.createContext<EventContext>(null!);

const kNotifKey = "event_notif";

interface MeterEventNotif extends MeterEventRaw {
  prompt: boolean;
  isAck: boolean;
}


export const EventProvider: React.FC = (props) => {

  const socketRef = useRef<any>();
  const [newNotifCount, setNewNotifCount] = useState(0);
  const [newEvents, setNewEvents] = useState<Array<MeterEventNotif>>([]);
  const account = useAppSelector(state => state.auth.user);

  const {
    data: eventDict,
    error,
    isSuccess,
    isFetching,
    refetch,
  } = useListEventDict({});

  useEffect(() => {
    if (account && eventDict) {
      socketRef.current = setupSocketIO();
    } else {
      if (socketRef.current) {
        tearDownSocketIO(socketRef.current);
      }
    }
  }, [account, eventDict]);

  useEffect(() => {
    if (newEvents.length > 0) {
      const index = newEvents.findIndex(e => e.prompt && !e.isAck)
      const event = newEvents[index];
      if (index === -1) {
        notification.close(kNotifKey);
      }
      else if (event.prompt) {
        presentNotification(event.eventdisc, event, index);
      }
    }
  }, [newEvents]);

  function onError(error: Error) {
    console.log('socket.io error', error);
  }

  function onConnect() {
    socketRef.current?.emit('clicentConnect', account?.accountid);
  }

  function onDisconnect() {
    console.log('onDisconnect ')
  }

  function onMeterEvent(msg: string) {
    const event = JSON.parse(msg);
    // console.log(msg)
    const code = event.disc.split('.')[1];
    const eventName: string = eventDict.event[code] ?? code;

    const newEvent: MeterEventNotif =  {
      meterno: event.deviceName,
      pointid: 0,
      eventdisc: translate(eventName),
      eventitemid: event.eventitemid,
      eventStartTime: event.eventtime,
      eventEndTime: event.eventtime,
      reportTime: event.eventtime,
      duration: 0,
      level: EventLevel.Info,
      alarmEventType: AlarmEventType.All,
      prompt: event.prompt,
      isAck: false,
    }

    setNewEvents(events => [ newEvent, ...events ]);
    setNewNotifCount(count => count + 1);

  }

  function presentNotification(name: string, event: MeterEventRaw, index: number) {
    const btn = (
      <Button
        className='btn btn-action btn-action-primary'
        onClick={() => {
          setNewNotifCount(count => Math.max(count - 1, 0));
          setNewEvents((events) => events.map((e, i) => index === i ? ({ ...e, isAck: true }) : e));
        }
      }><span className='text'>Confirm</span></Button>
    );

    notification['error']({
      message: `${name}`,
      description: (
        <Space direction="vertical">
          <span>{`Meter No.${event.meterno}`}<br/>{new Date(event.eventStartTime).toLocaleString('en-GB')}</span>
        </Space>
      ),
      duration: 0,
      key: kNotifKey,
      btn,
      top: 100,
      className: 'custom-notification-notice',
      placement: 'bottomRight',
    });
  }

  function setupSocketIO() {
    const socket = io(process.env.REACT_APP_SOCKETIO_URL ?? '', { autoConnect: false });
    socket.on('error', onError);
    // socket.on("meter:event", onMeterEvent);
    socket.on("chatevent", onMeterEvent);
    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.connect();
    return socket;
  }

  function tearDownSocketIO(socket: any) {
    console.log('>>>Teardown socket IO<<<');
    socket.off("chatevent", onMeterEvent);
    socket.off('connect', onConnect);
    socket.off('disconnect', onDisconnect);
    socket.close();
  }

  let value = {
    newNotifCount: newNotifCount,
    newEvents,
    clearNewNotifCount: () => setNewNotifCount(0),
    viewNotification: () => setNewNotifCount(count => Math.max(count - 1, 0))
  };

  return (
    <EventContext.Provider value={value}>{props.children}</EventContext.Provider>
  );
}


export enum DashboardEditMode {
  view,
  resize,
  remove,
}

interface DashboardContextType {
  mode: DashboardEditMode;
  setMode: (mode: number) => void;
  saveWidgets: () => void;
  discardWidgets: () => void;
  setWidgets: (widgets: Widget[]) => void;
  discardCount: number;
  widgets: Widget[] | undefined;
  isSavingWidgets: boolean;
  errorMessage: string;
  addTempWidget: (id: string) => void;
  addedWidgetId: string;
  setTarget:(node: TargetNode| undefined) => void;
  target: TargetNode | undefined;
  setStationAreaIds: (ids: number[]) => void;
  stationAreaIds: number[];
  expandWidgetCount: number;
  expandWidget: () => void;
  collapseWidgetCount: number;
  collapseWidget: () => void;
}

const DashboardContext = React.createContext<DashboardContextType>(null!);

export const DashboardProvider: React.FC = (props) => {

  let [mode, setMode] = useState(DashboardEditMode.view);
  let [widgets, setWidgets] = useState<Widget[]>([]);
  let [discardCount, setDiscardCount] = useState(0);
  let [expandWidgetCount, setExpandWidgetCount] = useState(0);
  let [collapseWidgetCount, setCollapseWidgetCount] = useState(0);
  let [addedWidgetId, setAddedWidgetId] = useState("");
  let [target, setTarget] = useState<TargetNode>();
  let [stationAreaIds, setStationAreaIds] = useState<number[]>([]);

  const [
    updateWidgets,
    {
      isLoading: isSaving,
      isSuccess,
      error,
    },
  ] = useUpdateWidgets({ fixedCacheKey: 'widgets' });

  useEffect(() => {
    if (isSuccess) {
      setMode(DashboardEditMode.view);
      setAddedWidgetId("");
    }
  }, [isSuccess])

  const serializeWidget = (widgets: Widget[]) => {
    const sorted = widgets.sort((a: Widget, b: Widget) => {
      let diff = a.y - b.y;
      if (diff === 0) {
        diff = a.x - b.x;
      }
      return diff;
    });
    return sorted;
  }

  const value = {
    mode,
    setMode,
    setWidgets: (widgets: Widget[]) => setWidgets(widgets),
    saveWidgets: () => updateWidgets(serializeWidget(widgets)),
    discardWidgets: () => {
      setMode(DashboardEditMode.view);
      setAddedWidgetId("");
      setDiscardCount(discardCount + 1);
    },
    widgets: widgets,
    isSavingWidgets: isSaving,
    discardCount,
    errorMessage: error ? JSON.stringify((error as FetchBaseQueryError).data) : '',
    addTempWidget: (id: string) => setAddedWidgetId(id),
    addedWidgetId,
    setTarget: setTarget,
    target: target,
    setStationAreaIds,
    stationAreaIds,
    expandWidgetCount,
    expandWidget: () => setExpandWidgetCount(c => c + 1),
    collapseWidgetCount,
    collapseWidget: () => setCollapseWidgetCount(c => c + 1),
  };

  return (
    <DashboardContext.Provider value={value}>{props.children}</DashboardContext.Provider>
  )
}


interface MapContextType {
  center: { lat: number, lng: number };
  zoom: number;
  key: string;
  setMeterId: (id: number) => void;
  meterId: number | undefined;
  setNodeMapper: (mapper: { [key: string]: Node }) => void;
  nodeMapper: Record<string, Node>
}
const MapContext = React.createContext<MapContextType>(null!);


export const MapProvider: React.FC = (props) => {
  const [meterId, setMeterId] = useState<number>();
  const [nodeMapper, setNodeMapper] = useState<{[key: string]: Node}>({});
  const value = {
    center: {
      lat: 13.8360819,
      lng: 100.6145865,
    },
    zoom: 11,
    key: process.env.REACT_APP_MAP_KEY || '',
    meterId,
    setMeterId,
    nodeMapper,
    setNodeMapper,
  };
  return <MapContext.Provider value={value} >{props.children}</MapContext.Provider>
}

export interface GenItem {
  name: string;
  value: string;
}

interface ComparisonContextType {
  addNode: (objectId: number, objectType: number, name: string) => void;
  removeNode: (objectId: number, objectType: number) => void;
  nodes: Array<TargetNode>;

  addRate: (rate: string) => void;
  removeRate: (rate: string) => void;
  rates: Array<GenItem>;
  setRateLoading: (isLoading: boolean) => void;
  isRateLoading: boolean;

  addRegion: (name: string, value: string) => void;
  removeRegion: (region: string) => void;
  regions: Array<GenItem>;
  setRegionLoading: (isLoading: boolean) => void;
  isRegionLoading: boolean;
}

const ComparisonContext = React.createContext<ComparisonContextType>(null!);


export const ComparisonProvider: React.FC = (props) => {
  const [nodes, setNodes] = useState<Array<TargetNode>>([]);
  const [rates, setRates] = useState<Array<GenItem>>([]);
  const [regions, setRegions] = useState<Array<GenItem>>([]);
  const [isRateLoading, setRateLoading] = useState<boolean>(false);
  const [isRegionLoading, setRegionLoading] = useState<boolean>(false);

  const addNode = (objectId: number, objectType: number, name: string) => {
    if (nodes.some(node => node.objid === objectId && node.objtype === objectType)) { return }

    setNodes([
      ...nodes,
      {
        objid: objectId,
        objtype: objectType,
        meterNo: name,
      },
    ]);
  }

  const removeNode = (objectId: number, objectType: number) => {
    setNodes(
      nodes.filter(node => !(node.objid === objectId && node.objtype === objectType)),
    );
  }

  const addRate = (rate: string) => {
    setRates(rates => [{ name: rate, value: rate}, ...rates]);
  }

  const removeRate = (rate: string) => {
    setRates(rates => rates.filter(r => r.value !== rate));
  }

  const addRegion = (name: string, value: string) => {
    setRegions(regions => [{ name, value }, ...regions]);
  }

  const removeRegion = (region: string) => {
    setRegions(regions => regions.filter(r => r.value !== region));
  }


  const value = {
    addNode,
    removeNode,
    nodes,
    addRate,
    removeRate,
    rates,
    addRegion,
    removeRegion,
    regions,
    isRateLoading,
    setRateLoading,
    isRegionLoading,
    setRegionLoading,
  };

  return <ComparisonContext.Provider value={value} >{props.children}</ComparisonContext.Provider>
}

export function useAuth() {
  return React.useContext(AuthContext);
}

export function useEvent() {
  return React.useContext(EventContext);
}

export function useDashboard() {
  return React.useContext(DashboardContext);
}

export function useMap() {
  return React.useContext(MapContext);
}

export function useComparison() {
  return React.useContext(ComparisonContext);
}
