import React, { useEffect, useRef, useState } from 'react';
import './FloorScene.css';
import {
    Button,
    Space,
    Tabs,
} from 'antd';
import moment from 'moment';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { useMap } from '../Providers';
import {
    Node,
  } from '../services/projectApi';
import { useGetBilling } from '../services/billingApi';

const { TabPane } = Tabs;


interface Room {
    name: string;
    width: number;
    height: number;
    meterNo: string;
    kwh?: number | undefined;
}

const rawRooms: Array<Room> = [
    {
        name: "F101",
        width: 20,
        height: 40,
        meterNo: "",
        kwh: 102
    },
    {
        name: "F102",
        width: 20,
        height: 40,
        meterNo: "",
        kwh: 4109
    },
    {
        name: "F103",
        width: 20,
        height: 40,
        meterNo: "",
    },
    {
        name: "F104",
        width: 20,
        height: 40,
        meterNo: "",
    },
    {
        name: "F105",
        width: 20,
        height: 40,
        meterNo: "",
        kwh: 2120,
    },
    {
        name: "F106",
        width: 20,
        height: 40,
        meterNo: "",
    },
    {
        name: "F107",
        width: 20,
        height: 40,
        meterNo: "",
    },
    {
        name: "F108",
        width: 20,
        height: 40,
        meterNo: "",
    },
    {
        name: "F109",
        width: 20,
        height: 40,
        meterNo: "006500000077",
    },
    {
        name: "F110",
        width: 20,
        height: 40,
        meterNo: "",
    },
    {
        name: "F111",
        width: 20,
        height: 40,
        meterNo: "",
    },
    {
        name: "F112",
        width: 20,
        height: 40,
        meterNo: "",
    },
    {
        name: "F113",
        width: 20,
        height: 40,
        meterNo: "",
    },
    {
        name: "F114",
        width: 20,
        height: 40,
        meterNo: "",
    },
    {
        name: "F115",
        width: 20,
        height: 40,
        meterNo: "",
    },
    {
        name: "F116",
        width: 20,
        height: 40,
        meterNo: "",
    },
    {
        name: "F201",
        width: 20,
        height: 40,
        meterNo: "",
    },
    {
        name: "F202",
        width: 20,
        height: 40,
        meterNo: "",
    },
    {
        name: "F203",
        width: 20,
        height: 40,
        meterNo: "",
        kwh: 120,
    },
    {
        name: "F204",
        width: 20,
        height: 40,
        meterNo: "",
    },
    {
        name: "F205",
        width: 20,
        height: 40,
        meterNo: "",
    },
    {
        name: "F206",
        width: 20,
        height: 40,
        meterNo: "",
    },
    {
        name: "F207",
        width: 20,
        height: 40,
        meterNo: "",
    },
    {
        name: "F208",
        width: 20,
        height: 40,
        meterNo: "",
    },
    {
        name: "F209",
        width: 20,
        height: 40,
        meterNo: "",
    },
    {
        name: "F210",
        width: 20,
        height: 40,
        meterNo: "",
    },
    {
        name: "F211",
        width: 20,
        height: 40,
        meterNo: "",
    },
    {
        name: "F212",
        width: 20,
        height: 40,
        meterNo: "",
    },
    {
        name: "F213",
        width: 20,
        height: 40,
        meterNo: "",
    },
    {
        name: "F214",
        width: 20,
        height: 40,
        meterNo: "",
    },
    {
        name: "F215",
        width: 20,
        height: 40,
        meterNo: "",
    },
    {
        name: "F216",
        width: 20,
        height: 40,
        meterNo: "",
    },
]

interface Rprops {
    room: Room;
    node: Node | null;
    idx: number;
    dy: number;
    name: string;
}

const RoomC: React.FC<Rprops> = (props) => {
    const {
        room,
        node,
        idx,
        dy,
        name,
    } = props;

    let params: any = {
    };

    if (node) {
        params = {
            objectId: node.objid,
            objectType: node.objtype,
            start: moment().add(-1, 'month').startOf('month').utcOffset(0, true).format('YYYY-MM-DD'),
            end: moment().startOf('month').utcOffset(0, true).format('YYYY-MM-DD'),
            page: 1,
        }
    }

    let [kwh, setKwh] = useState<string | null>()

    const {
        data,
        isFetching,
        isSuccess,
        error,
        refetch,
    } = useGetBilling(params, { skip: !node });

    useEffect(() => {
        if (!isFetching && isSuccess && data) {
            setKwh(data.importKwh);
        }
    }, [isFetching, isSuccess, data]);

    return (
        <g key={`${name}${idx}`} onClick={() => refetch()}>
            <rect x={(room.width + 2) * idx + 2 + 14} y={2 + dy} width={room.width} height={room.height} rx="1"/>
            <text x={(room.width + 2) * idx + 4 + 14} y={room.height + dy} className='room-label'>{room.name}</text>
            {node ? <text x={(room.width + 2) * idx + 4 + 14} y={dy + 6} className='meter-label'>Meter No.</text> : null}
            {node ? <text x={(room.width + 2) * idx + 4 + 14} y={dy + 10} className='meter-no'>{node.disc}</text> : null}
            {kwh ? <text x={(room.width + 2) * idx + 4 + 14} y={dy + 14} className='meter-label'>{`${kwh} kWh`}</text> : null}
            {node ? <circle cx={(room.width + 2) * idx + 4 + 8 + room.width} cy={5 + dy} r=".7" className={node.curstatus == 1 ? 'room-meter-online-status' : 'room-meter-offline-status'}/> : null}
        </g>);
}


const FloorScene: React.FC = () => {

    let [rooms1, setRooms1] = useState<Array<Room>>([]);
    let [rooms2, setRooms2] = useState<Array<Room>>([]);
    let [rooms3, setRooms3] = useState<Array<Room>>([]);
    let [rooms4, setRooms4] = useState<Array<Room>>([]);
    let [showFloor, setShowFloor] = useState('f1');

    const { state} = useLocation();
    const navigate = useNavigate();
    const map = useMap(); 

    function getNode(roomName: string) {
        const roomMeterMapper: Record<string, string> = {
            // 'F101': '218-10',
            // 'F102': '212-10',
            // 'F105': '217-10',
            // 'F109': '219-10',
            'F109': '225-10',
            
        }
        const keyPath = roomMeterMapper[roomName];
        if (keyPath) {
            return map.nodeMapper[keyPath];
        }
        return null;
    }

    useEffect(() => {
        const f1 = rawRooms.filter(r => r.name.startsWith('F1'))
        setRooms1(f1.slice(0, 8));
        setRooms2(f1.slice(8));

        const f2 = rawRooms.filter(r => r.name.startsWith('F2'))
        setRooms3(f2.slice(0, 8));
        setRooms4(f2.slice(8));
    }, [])

    return (
        <div className='floor-page-container'>
            <div className='floor-container'>
                {/* <Button type="text" style={{ width: 100 }} onClick={() => navigate('/app/maps')}>Back to Map</Button> */}
                <span className='subtitle subtitle--bigger-2'>{(state as any).name}</span>
                <svg viewBox="0 0 220 100" xmlns="http://www.w3.org/2000/svg">
                    <g id="f1" visibility={showFloor === 'f1' ? 'visible' : 'hidden'}>
                        <g fill="white" stroke="darkGrey" strokeWidth="0">
                            {rooms1.map((room, idx) => {
                                const node = getNode(room.name)
                                return <RoomC name="f1line1" room={room} node={node} idx={idx} dy={0}/>
                                })
                                }
                        </g>
                        <g fill="white" stroke="darkGrey" strokeWidth="0">
                            {rooms2.map((room, idx) => {
                            const node = getNode(room.name)
                            return <RoomC name="f1line2" room={room} node={node} idx={idx} dy={56}/>
                                })
                                }
                        </g>
                            <polyline
                                points="2, 0 12, 0 12, 45 190, 45 190, 55 12, 55 12, 100 2, 100"
                                stroke="orange"
                                fill="grey"
                                strokeWidth="0"
                                radius="1"
                            />
                            <text className="walk-way-label" x="100" y="51">Walk way</text>
                            <text className="floor-label" x="210" y="3">Floor 1</text>
                    </g>
                    <g id="f2" visibility={showFloor === 'f2' ? 'visible' : 'hidden'}>
                        <g fill="white" stroke="darkGrey" strokeWidth="0">
                            {rooms3.map((room, idx) => {
                                const node = getNode(room.name)
                                return <RoomC name="f2line1" room={room} node={node} idx={idx} dy={0}/>
                                })
                            }
                        </g>
                        <g fill="white" stroke="darkGrey" strokeWidth="0">
                            {rooms4.map((room, idx) => {
                                const node = getNode(room.name)
                                return <RoomC name="f2line2" room={room} node={node} idx={idx} dy={56}/>
                                })
                            }
                        </g>
                            <polyline
                                points="2, 0 12, 0 12, 45 190, 45 190, 55 12, 55 12, 100 2, 100"
                                stroke="orange"
                                fill="grey"
                                strokeWidth="0"
                                radius="1"
                            />
                            <text className="walk-way-label" x="100" y="51">Walk way</text>
                            <text className="floor-label" x="210" y="3">Floor 2</text>
                    </g>
                </svg>
            <div>
            </div>
            </div>
            <Space direction="vertical" className='segment' style={{ justifyContent: 'flex-start', alignItems: 'center', width: 120 }}>
                <span>Floors</span>
                <Button className={showFloor === 'f2' ? 'selected' : ''} onClick={() => setShowFloor('f2')}>Floor 2</Button>
                <Button className={showFloor === 'f1' ? 'selected' : ''} onClick={() => setShowFloor('f1')}>Floor 1</Button>
            </Space>

        </div>
    );
}

export default FloorScene;
