import React, { useEffect, useState } from 'react';
import {
    Outlet,
    useNavigate,
    useLocation,
} from "react-router-dom";
import {
  Affix,
  Badge,
  Button,
  Drawer,
  Dropdown,
  Layout,
  Menu,
  Modal,
  Space,
  notification,
} from 'antd';
import {
  BellIcon,
  BinIcon,
  ChartIcon,
  CloseIcon,
  CollapseIcon,
  DotIcon,
  EditIcon,
  PersonIcon,
  PinOutlineIcon,
  PlusIcon,
  SmartTTCIcon,
  ComparisonIcon,
  HamIcon,
  ExpandIcon,
} from '../icons';
import Clock from '../components/Clock';
import ProjectTreeContainer from '../components/ProjectTreeContainer';
import PushButton from '../components/PushButton';

import { DashboardEditMode, useAuth, useDashboard, useEvent } from '../Providers';
import './MainLayout.css';
import WidgetList from '../components/WidgetList';
import appPackage from '../../package.json';
import NotificationList from '../components/NotificationList';
import { useSelector } from 'react-redux';
import { RootState } from '../store';


const { Footer, Header, Content, Sider } = Layout;

const customerMenuEnabled = process.env.REACT_APP_CUSTOMER_MENU_ENABLED;
const mapMenuEnabled = process.env.REACT_APP_MAP_MENU_ENABLED;
const message = process.env.REACT_APP_MESSAGE;

const kNotifKey = "event_notif";

const MainLayout: React.FC = () => {

  let navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const dashboard = useDashboard();
  const event = useEvent();
  const title = location.pathname === '/app/dashboard' ? "AMI SYSTEM OPERATION" : "AMI SYSTEM OPERATION";
  const [isShowNotification, setShowNotification] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [showSubMenu, setShowSubmenu] = useState(false);
  const isTokenExpired = useSelector((state: RootState) => state.auth.isTokenExpired);

  function showConfirm() {
    Modal.confirm({
      title: 'Sign Out',
      content: 'Do you want to sign out?',
      okText: 'Sign Out',
      okType: 'danger',
      onOk() {
        auth.signout(() => { notification.close(kNotifKey); })
      },
      onCancel() {
      },
    });
  }

  useEffect(() => {
    if (isTokenExpired) {
      Modal.error({
        title: 'Session Expired',
        content: 'Please sign in again',
        okText: 'Go to sign in',
        closable: false,
        onOk() {
          auth.signout(() => { notification.close(kNotifKey); });
        },
      });
    }
  },
  [isTokenExpired]);

  const menu = (
    <Menu>
      <Menu.ItemGroup title={auth.account?.username ?? '-'}>
        <Menu.Item key="metereventname" onClick={() => window.open('/help.pdf', '_blank_')}>
          Meter Event Names
        </Menu.Item>
        <Menu.Item key="signout" onClick={() => showConfirm()}>
          Sign Out
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );

  function mainMenu() {
    return (
      <Space size="large" className='main-menu'>
        {mapMenuEnabled
        ? <Button
            icon={<PinOutlineIcon size={30}/>}
            className={['/app/maps', '/app/floors'].includes(location.pathname)  ? "btn btn--selected" : "btn"}
            onClick={() => navigate('/app/maps')}
          />
        : null
        }
        <Button
          icon={<ChartIcon size={30}/>}
          className={location.pathname === '/app/dashboard' ? "btn btn--selected" : "btn"}
          onClick={() => navigate('/app/dashboard')}
        />
        {customerMenuEnabled
        ? <Button
            icon={<PersonIcon size={30}/>}
            className={location.pathname === '/app/customer' ? "btn btn--selected" : "btn"}
            onClick={() => navigate('/app/customer')}
          />
        : null
        }
        <Button
          icon={<ComparisonIcon size={30}/>}
          className={location.pathname === '/app/comparison' ? "btn btn--selected" : "btn"}
          onClick={() => navigate('/app/comparison')}
        />
      </Space>
    )
  }

  function editWidgetMenu() {
    return (
      <Space size="large" className='edit-widget-menu'>
        <Button
          className='btn btn-action'
          disabled={dashboard.isSavingWidgets}
          onClick={() => dashboard.discardWidgets()}>
            <span className='text'>DISCARD</span>
        </Button>
        <Button
          className='btn btn-action'
          disabled={dashboard.isSavingWidgets}
          onClick={() => dashboard.saveWidgets()}>
            <span className='text'>SAVE</span>
        </Button>
    </Space>
    )
  }

  function widgetMenu() {
    return (
      <Space size="large" className='edit-widget-menu'>
        {
         dashboard.mode === DashboardEditMode.view
         ? <Button className="btn widget-menu" icon={<EditIcon />} onClick={() => dashboard.setMode(DashboardEditMode.resize)}/>
         : <PushButton icon={<BinIcon />} onClick={(selected: boolean) => dashboard.setMode(selected ? DashboardEditMode.remove : DashboardEditMode.resize)}/>
        }
      </Space>
    );
  }

  function floatingMainMenu() {
    return (
      <Affix className="float-main-menu" offsetBottom={24}>
        <div>
          <Button
            className="btn"
            icon={<HamIcon size={30} />}
            onClick={() => setMenuOpen(true)} />
          {showSubMenu
          ?
              <Space direction='vertical'>
                {location.pathname === "/app/dashboard" ?
                <>
                <Button
                  className="btn widget-menu"
                  icon={<ExpandIcon />}
                  onClick={() => dashboard.expandWidget()}
                />
                <Button
                  className="btn widget-menu"
                  icon={<CollapseIcon />}
                  onClick={() => dashboard.collapseWidget()}
                />
                <Button
                  className="btn widget-menu"
                  icon={<EditIcon />}
                  onClick={() => dashboard.setMode(DashboardEditMode.resize)}
                /> </>: null }
                <Button
                  className="btn widget-menu"
                  icon={<CloseIcon />}
                  onClick={() => setShowSubmenu(false)}
                />
              </Space>
          : <Button
              className="btn"
              icon={<DotIcon size={30} />}
              onClick={() => setShowSubmenu(true)} />}
        </div>
      </Affix>
    )
  }

  function floatingWidgetMenu() {
    return (
      <Affix className="float-main-menu" offsetBottom={24}>
        <div>
          <Button
              className="btn"
              icon={<PlusIcon size={30} />}
              onClick={() => setMenuOpen(true)} />
          {
            dashboard.mode === DashboardEditMode.view
            ? <Button className="btn widget-menu" icon={<EditIcon />} onClick={() => dashboard.setMode(DashboardEditMode.resize)}/>
            : <PushButton icon={<BinIcon />} onClick={(selected: boolean) => dashboard.setMode(selected ? DashboardEditMode.remove : DashboardEditMode.resize)}/>
          }
          <Button
            className='btn btn-action'
            disabled={dashboard.isSavingWidgets}
            onClick={() => dashboard.discardWidgets()}>
              <span className='text'>DISCARD</span>
          </Button>
          <Button
            className='btn btn-action'
            disabled={dashboard.isSavingWidgets}
            onClick={() => dashboard.saveWidgets()}>
              <span className='text'>SAVE</span>
          </Button>
        </div>
      </Affix>
    )
  }

  function onClose() {
    setMenuOpen(false);
  }

  return (
    <>
    <Layout>
      <Header className="nav-header">
        <div className="nav-title">
          <span className='head'>{title}</span>
        </div>
        <div className="nav-menu">
          {dashboard.mode === DashboardEditMode.view
          ? mainMenu()
          : null
          }
          {location.pathname === "/app/dashboard" && dashboard.mode !== DashboardEditMode.view
          ? editWidgetMenu()
          : null
          }
          <div style={{flex: 1}}></div>
          {location.pathname === "/app/dashboard"
            ? widgetMenu()
            : null }
          <Space size="large">
            <Clock />
            <Dropdown overlay={menu} placement="bottomRight" trigger={['click']} arrow>
              <Button className="btn" icon={<DotIcon />} />
            </Dropdown>
              <Badge count={event.newNotifCount}>
                <PushButton
                  icon={<BellIcon />}
                  onClick={(selected: boolean) => {
                    setShowNotification(selected);
                    event.clearNewNotifCount();
                  }} />
              </Badge>
          </Space>
        </div>
        <div className="nav-logo">
          <SmartTTCIcon size={60}/>
        </div>
      </Header>
      <Layout className='main-layout'>
        <Sider
          width={300}
          className="side-menu"
          theme='light'
          breakpoint="sm"
          collapsedWidth="0"
          collapsible={true}
          trigger={null}
          onBreakpoint={broken => {
            console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}>
          {
            dashboard.mode === DashboardEditMode.view
            ? <ProjectTreeContainer></ProjectTreeContainer>
            : <WidgetList></WidgetList>
          }
        </Sider>

        <Layout className="site-layout">
          <Content>
          <Outlet/>
          {isShowNotification ? <NotificationList />: null}
          {dashboard.mode === DashboardEditMode.view
          ? floatingMainMenu()
          : null
          }
          {location.pathname === "/app/dashboard" && dashboard.mode !== DashboardEditMode.view
          ? floatingWidgetMenu()
          : null
          }
        </Content>
      </Layout>
      </Layout>
      <Footer className='footer'>
        <span>© 2024 Smart TTC. All Rights Reserved</span>
        <span className='version'>{[message, `V.${appPackage.version}`].filter(item => item).join(' | ')}</span>
      </Footer>
    </Layout>

    <Drawer
      width={300}
      placement="left"
      headerStyle={{ display: 'none' }}
      onClose={onClose}
      visible={isMenuOpen}
    >
       <Layout>
        <Header className="nav-header">
          <div className={`nav-title ${dashboard.mode !== DashboardEditMode.view ? 'forced-show' : ''}`}>
            <span className='head'>{title}</span>
          </div>
          <div className="nav-menu">
            {dashboard.mode === DashboardEditMode.view
            ? mainMenu()
            : null
            }
          </div>
        </Header>
        <Layout className='main-layout'>
        <Sider
          width={300}
          className="side-menu"
          theme='light'>
          {
            dashboard.mode === DashboardEditMode.view
            ? <ProjectTreeContainer></ProjectTreeContainer>
            : <WidgetList></WidgetList>
          }
          </Sider>
        </Layout>
      </Layout>
    </Drawer>
    </>
  );
}

export default MainLayout;
