import React from 'react';
import {
  Outlet,
} from "react-router-dom";
import { Layout } from 'antd';
import appPackage from '../../package.json';


const {
  Footer,
  Content
} = Layout;

const message = process.env.REACT_APP_MESSAGE;

const AuthLayout: React.FC = () => {
  return (
    <Layout>
      <Content>
        <Outlet />
      </Content>
      <Footer className='footer footer-login'>
          <span>© 2024 Smart TTC. All Rights Reserved</span>
          <span className='version'>{[message, `V.${appPackage.version}`].filter(item => item).join(' | ')}</span>
      </Footer>
    </Layout>
  );
}

export default AuthLayout;
