import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Button,
  Select,
  Space,
  Table,
  DatePicker,
  TablePaginationConfig,
} from 'antd';
import {
  TableCell,
  TableTitle
} from './Table';
import {
  MeterProps,
} from './MeterProps';
import {
  AlarmEventType,
  MeterEvent,
  useListMeterEvent
} from '../services/eventApi';

const PAGE_SIZE = 10;

const { Option } = Select;
const { RangePicker } = DatePicker;


const alarmEventTypes: AlarmEventType[] = [
  AlarmEventType.All,
  AlarmEventType.PowerQuality,
  AlarmEventType.Fraud,
  AlarmEventType.Standard,
];


export const EventLogs: React.FC<MeterProps> = (props) => {
  let [startDate, setStartDate] = useState(new Date(new Date().valueOf() - 30 * 86400 * 1000));
  let [endDate, setEndDate] = useState(new Date());
  let [page, setPage] = useState(1);
  let [alarmEventType, setAlarmEventType] = useState<AlarmEventType>(AlarmEventType.All);

  const params = {
    meterId: props.meterId,
    eventType: alarmEventType ?? 0,
    start: moment(startDate).utcOffset(0, true).format('YYYY-MM-DD'),
    end: moment(endDate).utcOffset(0, true).format('YYYY-MM-DD'),
    page,
  }

  const {
    data, isFetching, isSuccess, refetch,
  } = useListMeterEvent(params);
  const [events, setEvents] = useState<MeterEvent[]>([]);

  const columns = [
    {
      title: () => <TableTitle>Meter No.</TableTitle>,
      render: (event: MeterEvent) => <TableCell>{event.meterno}</TableCell>
    },
    {
      title: () => <TableTitle>Event Name</TableTitle>,
      render: (event: MeterEvent) => <TableCell>{event.eventdisc}</TableCell>
    },
    {
      title: () => <TableTitle>Event Time</TableTitle>,
      render: (event: MeterEvent) => <TableCell>{event.eventStartTime.toLocaleString('en-GB')}</TableCell>
    },
    {
      title: () => <TableTitle>Report Time</TableTitle>,
      render: (event: MeterEvent) => <TableCell>{event.reportTime.toLocaleString('en-GB')}</TableCell>
    },
  ];

  useEffect(() => {
    if (!isFetching && isSuccess && data) {
      setEvents(data.objs.map(item => ({ ...item, eventStartTime: new Date(item.eventStartTime), eventEndTime: new Date(item.eventEndTime), reportTime: new Date(item.reportTime), isNew: false })));
    }
  }, [isFetching, isSuccess, data, props.meterNo]);

  return (
    <>
      <Space style={{ height: 35, margin: 0, marginLeft: 10, marginRight: 10, justifyContent: 'flex-end' }}>
        <Select
          value={alarmEventType}
          size='small'
          style={{ width: 200 }}
          disabled={isFetching}
          placeholder="Alarm Event Type"
          optionFilterProp="children"
          filterOption={(input, option) => (option?.value as string).toLowerCase().indexOf(input.toLowerCase()) >= 0}
          onSelect={(val: AlarmEventType) => setAlarmEventType(val)}
        >
          {alarmEventTypes.map(eventType => <Option key={eventType} value={eventType}>{AlarmEventType[eventType]}</Option>)}
        </Select>
        <RangePicker
          size='small'
          disabled={isFetching}
          defaultValue={[moment(startDate), moment(endDate)]} onChange={(values, formatString) => {
            if (values && values[0]) {
              setStartDate(values[0].toDate());
            }
            if (values && values[1]) {
              setEndDate(values[1].toDate());
            }
          }} />
        <Button size='small' onClick={refetch}>Reload</Button>
      </Space>
      <div className='widget' style={{ flex: 1, margin: 10 }}>
        <div className='header'>
          <span className='subtitle subtitle--bigger-1'>{`Events of Meter No.${props.meterNo}`}</span>
        </div>
        <div className='body'>
          <Table
            loading={isFetching}
            style={{ width: '100%', height: '100%' }}
            className="chart"
            dataSource={events}
            columns={columns}
            pagination={{
              position: ['bottomCenter'],
              showSizeChanger: false,
              pageSize: PAGE_SIZE,
              total: data ? data.total : undefined
            }}
            onChange={(pagination: TablePaginationConfig) => {
              if (pagination.current) {
                setPage(pagination.current)
              }
            }}
            />
        </div>
      </div>
    </>
  );
};
