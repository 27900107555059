import React from 'react';
import {
    Button,
    Badge,
    Descriptions,
    Spin,
    Space,
    Table,
    Col,
} from 'antd';
import { BillingRaw, useGetBilling } from '../services/billingApi';
import moment from 'moment';
import {
    TableCell,
    TableTitle
  } from './Table';

import './Invoice.css';
import { SmartTTCIcon } from '../icons';

function formatDateTime(date: Date) {
    return date.toLocaleString('en-GB', { hour: '2-digit', minute: '2-digit', day: '2-digit', month: '2-digit', year: 'numeric' })
}

function formatBillingPeriod(date: Date) {
    return date.toLocaleString('en-GB', { month: '2-digit', year: 'numeric' })
}

function formatNumber(val: number) {
    return val.toLocaleString(undefined, { minimumFractionDigits: 3 })
}

interface Props {
    objectId: number,
}

export const Invoice: React.FC<Props> = (props) => {

    let params = {
        objectId: props.objectId,
        objectType: 10,
        start: moment().add(-4, 'month').startOf('month').utcOffset(0, true).format('YYYY-MM-DD'),
        end: moment().startOf('month').utcOffset(0, true).format('YYYY-MM-DD'),
        page: 1,
    }

    const {
        data: billing,
        isFetching,
        isSuccess,
        error,
        refetch,
    } = useGetBilling(params);

    const columns = [
        {
          title: () => <TableTitle>Billing Period</TableTitle>,
          render: (bill: BillingRaw) => <TableCell>{formatBillingPeriod(new Date(bill.dataTime))}</TableCell>
        },
        {
          title: () => <TableTitle>Cummulative Energy</TableTitle>,
          render: (bill: BillingRaw) => <TableCell>{formatNumber(parseFloat(bill.importKwh))}</TableCell>
        },
        {
          title: () => <TableTitle>Energy Consumption</TableTitle>,
          render: (bill: BillingRaw) => <TableCell>{formatNumber(parseFloat(bill.currentImportKwh))}</TableCell>
        },
        {
          title: () => <TableTitle>Max. Demand</TableTitle>,
          render: (bill: BillingRaw) => <TableCell>{formatNumber(parseFloat(bill.maxDemand))}</TableCell>
        },
      ];
    

    return (
        <Spin spinning={isFetching}>
            {billing
            ? <>
                <div style={{display: 'flex', flexFlow: 'row', alignItems: 'center'}}>
                    <div style={{flex: 1}}>
                    <span>
                    <label><b>Name:</b></label> John Doe <br />
                    <label><b>Address:</b></label> 444 Phayathai Road, Pathumwan District, Bangkok 10330 <br/><br/>
                    </span>
                    </div>
                <SmartTTCIcon size={48}/>
            </div>
            <Descriptions title="" layout="vertical" bordered className='invoice'
                        column={{ xxl: 4, xl: 4, lg: 2, md: 1, sm: 1, xs: 1 }}>
                    <Descriptions.Item label="Meter No.">{billing.meterNo}</Descriptions.Item>
                    <Descriptions.Item label="User No.">MBK24XXX</Descriptions.Item>
                    <Descriptions.Item label="Billing Period">{formatBillingPeriod(new Date(billing.dataTime))}</Descriptions.Item>
                    <Descriptions.Item label="Recording Date">{formatDateTime(new Date(billing.readTime))}</Descriptions.Item>
                    <Descriptions.Item label="Billing Reset">{formatDateTime(new Date(billing.resettingDate))}</Descriptions.Item>
                    <Descriptions.Item label="Cumulative Energy">{formatNumber(parseFloat(billing.importKwh))}</Descriptions.Item>
                    <Descriptions.Item label="Energy Consumption">{formatNumber(parseFloat(billing.currentImportKwh))}</Descriptions.Item>
                    <Descriptions.Item label="Max. Demand">{formatNumber(parseFloat(billing.maxDemand))}</Descriptions.Item>
                </Descriptions>
                <span><br></br><u>Usage History</u></span>
                <Table
                    style={{ width: '100%', height: '100%' }}
                    className="chart invoice"
                    dataSource={billing.prev}
                    columns={columns}
                    pagination={{ position: [] }}
                    >
                </Table>
            </>
            : null} 
        </Spin>
    );

}