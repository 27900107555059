
import React, { useEffect, useState } from 'react';
import { Customer } from '../services/customerApi';
import {
  Button,
  Descriptions,
  Space,
  Select,
  Table,
  Tabs,
  Tag,
} from 'antd';
import {
  ReloadOutlined,
} from '@ant-design/icons';
import {
  TableCell,
  TableTitle,
} from '../components/Table';
import {
  useListCustomer,
  useListCustomerByStationArea,
} from '../services/customerApi';
import { useDashboard } from '../Providers';
import { useListRate } from '../services/meterDataApi';
import { NodeType, Region, useListRegion } from '../services/projectApi';
import {
  MeterOnlineIndicator,
  MeterOfflineIndicator,
} from '../icons';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import './CustomerScene.css';
import { Billings } from '../components/Billings';


const { Option } = Select;
const { TabPane } = Tabs;

interface MeterProps {
  readonly isOnline: boolean;
}

const MeterStatus: React.FC<MeterProps> = (props) => {
  return (
    props.isOnline
      ? <MeterOnlineIndicator />
      : <MeterOfflineIndicator />
  );

}

interface CustomerTableProps {
  readonly customers: Customer[];
  readonly isFetching: boolean;
  readonly regionDict: Record<string, string>;
}

const CustomerInfoTable: React.FC<CustomerTableProps> = (props) => {

  const columns = [
    {
      title: () => <TableTitle>Status</TableTitle>,
      render: (customer: Customer) => <TableCell>
        <MeterStatus isOnline={customer.isMeterOnline} />
      </TableCell>,
      fixed: 'left' as const,
      width: 60,
    },
    {
      title: () => <TableTitle>Name</TableTitle>,
      render: (customer: Customer) => <TableCell>{customer.discUser}</TableCell>,
      fixed: 'left' as const,
    },
    {
      title: () => <TableTitle>Meter No. (Smart TTC)</TableTitle>,
      render: (customer: Customer) => <TableCell>{customer.meterNoStr}</TableCell>,
    },
    {
      title: () => <TableTitle>Meter No. (PEA)</TableTitle>,
      render: (customer: Customer) => <TableCell>{customer.existingMeterNo}</TableCell>,
      responsive: ['md'] as Breakpoint[],
    },
    {
      title: () => <TableTitle>CA</TableTitle>,
      render: (customer: Customer) => <TableCell>{customer.puserNo}</TableCell>,
      width: 100,
      responsive: ['md'] as Breakpoint[],
    },
    {
      title: () => <TableTitle>Business Type</TableTitle>,
      render: (customer: Customer) => <TableCell>{customer.businessType}</TableCell>,
      responsive: ['md'] as Breakpoint[],
    },
    {
      title: () => <TableTitle>Rate</TableTitle>,
      render: (customer: Customer) => <TableCell>{customer.peano}</TableCell>,
      responsive: ['md'] as Breakpoint[],
    },
    {
      title: () => <TableTitle>PEA Code</TableTitle>,
      render: (customer: Customer) => <TableCell>{customer.peacode}</TableCell>,
      responsive: ['lg'] as Breakpoint[],
    },
    {
      title: () => <TableTitle>Voltage</TableTitle>,
      render: (customer: Customer) => <TableCell>{customer.voltage ?? "-"}</TableCell>,
      // width: 60,
      responsive: ['lg'] as Breakpoint[],
    },
  ];

  return (
    <Table
      loading={props.isFetching}
      style={{ height: '100%', width: '100%' }}
      className="chart"
      dataSource={props.customers}
      columns={columns}
      expandable={{
        expandedRowRender: customer => {
          let regionName;
          if (props.regionDict && customer.peaRegion) {
            regionName = props.regionDict[customer.peaRegion] ?? customer.peaRegion;
          }

          return (
            <Descriptions title="Detail" bordered>
              <Descriptions.Item label="Address" span={2}><TableCell>{customer.address}</TableCell></Descriptions.Item>
              <Descriptions.Item label="District"><TableCell>{customer.district}</TableCell></Descriptions.Item>
              <Descriptions.Item label="Province"><TableCell>{customer.province}</TableCell></Descriptions.Item>
              <Descriptions.Item label="Latitude"><TableCell>{customer.lat ?? '-'}</TableCell></Descriptions.Item>
              <Descriptions.Item label="Longitude"><TableCell>{customer.lng ?? '-'}</TableCell></Descriptions.Item>
              <Descriptions.Item label="PEA Region"><TableCell>{regionName ?? '-' }</TableCell></Descriptions.Item>
              <Descriptions.Item label="Customer Type"><TableCell>{customer.customerType ?? "-"}</TableCell></Descriptions.Item>
              <Descriptions.Item label="Installation"><TableCell>{customer.collection}</TableCell></Descriptions.Item>
              <Descriptions.Item label="Installation Date"><TableCell>{customer.installDate ? new Date(customer.installDate).toLocaleDateString('en-UK') : '-'}</TableCell></Descriptions.Item>
              <Descriptions.Item label="Remark"><TableCell>{customer.remark ?? "-"}</TableCell></Descriptions.Item>
            </Descriptions>
          )
        }
      }}
      pagination={{ position: ['bottomCenter'] }}
    />
  );
}

export enum BusinessType {
  All = "0",
  Small = "1",
  Medium =  "2",
  Large = "3",
}

export interface OptionType<T> {
  name: string;
  value: T;
}

export const businessTypes: Record<string, OptionType<BusinessType>> = {
  'All': { name: 'All', value: BusinessType.All },
  'Small': { name: 'Small Enterprise', value: BusinessType.Small },
  'Medium': { name: 'Medium Enterprise', value: BusinessType.Medium },
  'Large': { name: 'Large Enterprise', value: BusinessType.Large },
}

export const customerRates: Record<string, OptionType<string>> = {
  '1114': { name: 'บ้านอยู่อาศัย ไม่เกิน 5A. 22-33KV', value: '1114' },
  '1114M': { name: 'วัด,สถานประกอบศาสนกิจ ไม่เกิน 5A.22-33KV', value: '1114M' },
  '1115': { name: 'บ้านอยู่อาศัย ไม่เกิน 5A. <22.KV', value: '1115' },
  '1115M': { name: 'วัด,สถานประกอบศาสนกิจ ไม่เกิน 5A. <22KV', value: '1115M' },
  '1124': { name: 'บ้านอยู่อาศัย เกิน 5A. 22-33KV', value: '1124' },
  '1124M': { name: 'วัด,สถานประกอบศาสนกิจ เกิน 5A. 22-33KV', value: '1124M' },
  '1125': { name: 'บ้านอยู่อาศัย เกิน 5A. <22KV', value: '1125' },
  '1125M': { name: 'วัด,สถานประกอบศาสนกิจ เกิน 5A. <22KV', value: '1125M' },
  '1214': { name: 'บ้านอยู่อาศัย TOU 22-33KV', value: '1214' },
  '1214M': { name: 'วัด,สถานประกอบศาสนกิจ TOU 22-33KV', value: '1214M' },
  '1225': { name: 'บ้านอยู่อาศัย TOU <22KV', value: '1225' },
  '1225M': { name: 'วัด,สถานประกอบศาสนกิจ TOU <22KV', value: '1225M' },
  '2114': { name: 'กิจการขนาดเล็ก 22-33KV', value: '2114' },
  '2125': { name: 'กิจการขนาดเล็ก <22KV', value: '2125' },
  '2125C': { name: 'อุปกรณ์ทวนสัญญาณ (Cell station) <22KV', value: '2125C' },
  '2125T': { name: 'ตู้โทรศัพท์สาธารณะ <22KV', value: '2125T' },
  '2214': { name: 'กิจการขนาดเล็ก TOU 22-33KV', value: '2214' },
  '2215': { name: 'กิจการขนาดเล็ก TOU <22KV', value: '2215' },
}

interface CustomerData extends Customer {
  readonly key: string;
}

const CustomerScene: React.FC = () => {

  const dashboard = useDashboard();
  const [rates, setRates] = useState<Array<number>>([]);
  const [regionCode, setRegionCode] = useState<string | undefined>();
  const [regionDict, setRegionDict] = useState<Record<string, string>>({});
  const [customers, setCustomers] = useState<Array<CustomerData>>([]);

  const params = {
    objectId: dashboard.target?.objid,
    objectType: NodeType.DCU,
    rates: rates,
    regionCode,
  };

  const {
    data,
    isFetching,
    refetch,
  } = useListCustomer(params, { skip: !!!dashboard.target || dashboard.target?.objtype !== NodeType.DCU });

  const {
    data: data2,
    isFetching: isFetching2,
    isSuccess: isSuccess2,
    refetch: refetch2
  } = useListCustomerByStationArea({ ids: dashboard.stationAreaIds, rates: rates, regionCode })

  const {
    data: regions,
    isFetching: isFetchingRegion,
    isSuccess: isSuccessRegion,
    refetch: refetchRegion
  } = useListRegion({})

  const {
    data: data3,
    isFetching: isFetchingRate,
    isSuccess: isSuccessRate,
    refetch: refetchRate,
  } = useListRate({})

  useEffect(() => {
    if (!isFetching && isSuccess2 && data2) {
      setCustomers(
        data2.map<CustomerData>(customer => ({ key: String(customer.userid), ...customer }))
      )
    }
  }, [isFetching, isSuccess2, data2]);

  useEffect(() => {
    if (!isFetching && isSuccessRegion && regions) {
      setRegionDict(
        regions.reduce((accu, r) => ({ ...accu, [r.code] : r.name }), {})
      )
    }
  }, [isFetchingRegion, isSuccessRegion, regions]);

  function removeSelectedNode() {
    dashboard.setTarget(undefined);
    dashboard.setStationAreaIds([]);
  }

  return (
    <Tabs defaultActiveKey="1" className='customer-page'>
      <TabPane tab="Customers" key="1" className='tab-container customer-tab' style={{ flexFlow: 'column' }}>
        <Space style={{ margin: 10, marginLeft: 25, marginBottom:0, justifyContent: 'flex-end' }}>
          {dashboard.stationAreaIds.length > 0
            ? <Tag key="all_node" color="#FF6A00" closable onClose={() => removeSelectedNode()}>{dashboard.target?.meterNo}</Tag>
            : <Tag key="selected_node" color="#FFFFFF" style={{color: '#847463'}}>All Nodes</Tag>}
          <Select
            allowClear
            mode="multiple"
            placeholder="Rate Filter"
            disabled={isFetching || isFetching2}
            defaultValue={rates}
            onChange={(vals: Array<number>) => setRates(vals)}
            onClear={() => setRates([])}
            size="small"
            maxTagCount='responsive'
          >
            {!isFetchingRate && data3 ? data3.map(key =>
              <Option key={key} value={key}>{`${key}`}</Option>)
            :null}
          </Select>
          <Select
            allowClear
            placeholder="Region filter"
            disabled={isFetching || isFetching2 || isFetchingRegion}
            onSelect={(val: string) => setRegionCode(val)}
            onClear={() => setRegionCode(undefined)}
            size="small"
          >
            {(regions ?? []).map(region =>
              <Option key={`region-${region.code}`} value={region.code}>{region.name}</Option>)
            }
          </Select>
        </Space>
        <div className="tab-container" style={{ marginTop: 10, marginBottom: 10 }}>
          <div className='widget' style={{ flex: 1, margin: 10 }}>
            <div className='header'>
              <span className='subtitle subtitle--bigger-1'>{`Customer Info ${dashboard.target?.meterNo ? `in ${dashboard.target?.meterNo}` : ''}`}</span>
              <Button
                size="small"
                type='text'
                disabled={isFetching || isFetching2}
                onClick={() => {
                  refetch();
                  refetch2();
                }}
                icon={<ReloadOutlined />} />
            </div>
            <div className='body'>
              <CustomerInfoTable
                customers={(dashboard.target?.objtype !== NodeType.DCU ? customers : data) || []}
                isFetching={isFetching || isFetching2}
                regionDict={regionDict}
              />
            </div>
          </div>
        </div>
      </TabPane>
      <TabPane tab="Billings" key="2" className='tab-container customer-tab' style={{ flexFlow: 'column' }}>
        {dashboard.target ?
          <Billings
            objectId={dashboard.target.objid}
            objectType={dashboard.target.objtype}
            name={dashboard.target.meterNo ?? ""} />
          : null
        }
      </TabPane>
    </Tabs>
  );
}

export default CustomerScene;