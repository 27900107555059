import React, { useEffect, useState } from 'react';
import {
  Button,
  Space,
  Table,
  TablePaginationConfig,
  DatePicker,
  Modal,
  Image,
} from 'antd';
import moment from 'moment';
import {
  TableCell,
  TableTitle
} from './Table';
import {
  MeterProps,
} from './MeterProps';
import {
  BillingImage,
  useGetBilling,
  useListBilling,
} from '../services/billingApi';
import { formatDurtionStr } from '../utils/converter';
import { Invoice } from './Invoice';

const { RangePicker } = DatePicker;

const PAGE_SIZE = 10;

export interface Props {
  name: string;
  objectId: number;
  objectType: number;
}

export const Billings: React.FC<Props> = (props) => {
  let [page, setPage] = useState(1);
  let [startDate, setStartDate] = useState(moment().startOf('month').add(-1, 'month').toDate());
  let [endDate, setEndDate] = useState(new Date());
  let [showModal, setShowModal] = useState(false);
  let [toShowBill, setToShowBill] = useState<BillingImage>();

  const params = {
    objectId: props.objectId,
    objectType: props.objectType,
    start: moment(startDate).utcOffset(0, true).format('YYYY-MM-DD'),
    end: moment(endDate).utcOffset(0, true).format('YYYY-MM-DD'),
    page,
  }

  const {
    data, isFetching, isSuccess, refetch,
   } = useListBilling(params);

  const [billings, setBillings] = useState<BillingImage[]>([]);

  const formatDuration = (diff: number) => {
    return formatDurtionStr(diff, true);
  };

  const columns = [
    {
      title: () => <TableTitle>Meter No.</TableTitle>,
      render: (obj: BillingImage) => <TableCell>{obj.meterNo}</TableCell>
    },
    {
      title: () => <TableTitle>Billing Date</TableTitle>,
      render: (obj: BillingImage) => <TableCell>{obj.dataTime.toLocaleString('en-GB', { month: '2-digit', year: 'numeric' })}</TableCell>
    },
    {
      title: () => <TableTitle>Action</TableTitle>,
      render: (record: BillingImage) => (
        <Space size="middle">
          <Button type="link" onClick={() => {
                setShowModal(true);
                setToShowBill(record);
                }
          }>View</Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (!isFetching && isSuccess && data) {
      setBillings(data.objs.map<BillingImage>(item => ({ ...item, dataTime: new Date(item.dataTime) })));
    }
  }, [isFetching, isSuccess, data, props.name]);

  return (
    <>
      <Space style={{ height: 35, margin: 0, marginLeft: 10, marginRight: 10, justifyContent: 'flex-end' }}>
        <RangePicker
          size='small'

          disabled={isFetching}
          defaultValue={[moment(startDate), moment(endDate)]} onChange={(values, formatString) => {
            if (values && values[0]) {
              setStartDate(values[0].toDate());
            }
            if (values && values[1]) {
              setEndDate(values[1].toDate());
            }
          }} />
          <Button size='small' onClick={refetch}>Reload</Button>
      </Space>
      <div className='widget' style={{ flex: 1, margin: 10 }}>
        <div className='header'>
          <span className='subtitle subtitle--bigger-1'>
            {`Billing of ${props.name}`}
          </span>
        </div>
        <div className='body'>
          <Table
            loading={isFetching}
            style={{ width: '100%', height: '100%' }}
            className="chart"
            dataSource={billings}
            columns={columns}
            pagination={{
              position: ['bottomCenter'],
              showSizeChanger: false,
              pageSize: PAGE_SIZE,
              total: data ? data.total : undefined }}
            onChange={(pagination: TablePaginationConfig) => {
              if (pagination.current) {
                setPage(pagination.current)
              }
            }}
            />
        </div>
      </div>
      <Modal title="Invoice"
            onOk={() => {setShowModal(false)}}
            onCancel={() => {setShowModal(false)}}
            visible={showModal}
            width={700}
            cancelButtonProps={{hidden: true}}
            >
          <Invoice objectId={props.objectId}></Invoice>
      </Modal>
    </>
  );
};
