import {
    baseApi,
    Response,
    ObjectResponse,
    Pagination,
    ListResponse,
    ListResponseWithTotal,
} from './baseApi';


export interface BillingImage {
    readonly meterNo: string;
    readonly imageName: string;
    readonly imageUrl: string;
    readonly dataTime: Date;
}

export interface BillingImageRaw {
    readonly meterNo: string;
    readonly imageName: string;
    readonly imageUrl: string;
    readonly dataTime: string;
}

export interface BillingRaw {
    readonly meterNo: string;
    readonly importKw: string;
    readonly importKwh: string;
    readonly readTime: string;
    readonly dataTime: string;
    readonly maxDemand: string;
    readonly currentImportKwh: string;
    readonly resettingDate: string;
    readonly prev: Array<BillingRaw>;
}


const prefix = '/billings';

export const billingApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        listBillings: build.query<Pagination<BillingImageRaw>, { objectId: number, objectType: number, start: string, end: string, page: number }>({
            query: (params) => {
                return {
                    url:  `${prefix}/`,
                    method: 'GET',
                    params,
                }
            },
            transformResponse: (response: Response<Pagination<BillingImageRaw>>, meta, arg) => {
                const objs = response.data.objs;
                return {
                    ...response.data,
                    objs,
                }
            },
        }),
        getBilling: build.query<BillingRaw, { objectId: number, objectType: number, start: string, end: string, page: number }>({
            query: (params) => {
                return {
                    url:  `${prefix}/detail`,
                    method: 'GET',
                    params,
                }
            },
            transformResponse: (response: Response<BillingRaw>, meta, arg) => {
                return response.data;
            },
        }),
    }),
    overrideExisting: false,
});

export const {
    useListBillingsQuery: useListBilling,
    useGetBillingQuery: useGetBilling,
} = billingApi;
