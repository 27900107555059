import React, { useEffect, useState } from 'react';
import {
  Tabs,
} from 'antd';
import {
  useGetMeter,
} from '../services/meterDataApi';

import { useLocation } from 'react-router-dom';
// import { TodayYesterdayLoadProfilePane } from '../components/TodayYesterdayLoadProfilePane';
import { YTLTodayYesterdayLoadProfilePane } from '../components/YTLTodayYesterdayLoadProfilePane';
// import { ImportExportLoadProfilePane } from '../components/ImportExportLoadProfilePane';
import { YTLImportExportLoadProfilePane } from '../components/YTLImportExportLoadProfilePane';
import { Comparison } from '../components/Comparison';
import { EventLogs } from '../components/EventLogs';
import LoadProfileSummary from '../components/LoadProfileSummary';
import MeterSettings from '../components/MeterSettings';
import { useAuth } from '../Providers';
import { OfflineEventLogs } from '../components/OfflineLogs';
import { Billings } from '../components/Billings';

const { TabPane } = Tabs;


const MeterScene: React.FC = () => {

  const { state } = useLocation();
  const auth = useAuth();
  const params = { meterId: (state as any).meterId };
  const [meterNo, setMeterNo] = useState('');
  const [startToLoad, setStartToLoad] = useState(false);

  const {
    data,
    isFetching,
    isSuccess,
  } = useGetMeter(params, { skip: !startToLoad });

  useEffect(() => {
    if (isSuccess && !isFetching) {
      setMeterNo(data.meterno);
      setStartToLoad(false);
    }
  }, [isSuccess, isFetching]);

  useEffect(() => {
    setStartToLoad(true);
  }, [(state as any).meterId]);

  return (
    <Tabs defaultActiveKey="1" className="meter-tabs">
      <TabPane tab="Load Profile" key="1" className='tab-container' style={{ flexFlow: 'column' }}>
      <YTLTodayYesterdayLoadProfilePane
          name={(state as any).name}
          dcuId={(state as any).dcuId}
          meterId={(state as any).meterId}
          meterNo={meterNo}
        />
      </TabPane>
      <TabPane tab="Load Profile by Direction" key="1.1" className='tab-container' style={{ flexFlow: 'column' }}>
      <YTLImportExportLoadProfilePane
          name={(state as any).name}
          dcuId={(state as any).dcuId}
          meterId={(state as any).meterId}
          meterNo={meterNo}
        />
      </TabPane>
      <TabPane tab="Comparison" key="2" className='tab-container' style={{ flexFlow: 'column' }}>
        <Comparison
          name={(state as any).name}
          dcuId={(state as any).dcuId}
          meterId={(state as any).meterId}
          meterNo={meterNo}
          />
      </TabPane>
      <TabPane tab="Event Log" key="3" className='tab-container' style={{ flexFlow: 'column' }}>
        <EventLogs
          name={(state as any).name}
          dcuId={(state as any).dcuId}
          meterId={(state as any).meterId}
          meterNo={meterNo}
        />
      </TabPane>
      <TabPane tab="Offline Log" key="30" className='tab-container' style={{ flexFlow: 'column' }}>
        <OfflineEventLogs
          name={(state as any).name}
          dcuId={(state as any).dcuId}
          meterId={(state as any).meterId}
          meterNo={meterNo}
        />
      </TabPane>
      <TabPane tab="Billings" key="31" className='tab-container' style={{ flexFlow: 'column' }}>
        <Billings
          objectId={(state as any).meterId}
          objectType={10}
          name={`Meter No. ${meterNo}`}
        />
      </TabPane>
      <TabPane tab="Summary" key="4" className='tab-container' style={{ flexFlow: 'column' }}>
        <LoadProfileSummary
          name={(state as any).name}
          dcuId={(state as any).dcuId}
          meterId={(state as any).meterId}
          meterNo={meterNo}
        />
      </TabPane>
      {auth.isAdmin
      ? <TabPane tab="Settings" key="5" className='tab-container' style={{ flexFlow: 'column' }}>
          <MeterSettings
            objectId={(state as any).meterId}
            name={(state as any).name}
            meterNo={meterNo}
          />
        </TabPane>
      : null
      }
    </Tabs>
  );
}

export default MeterScene;
