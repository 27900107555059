import React, {
    useEffect,
    useRef,
    useState,
} from 'react';
import {
  Spin,
  Tag,
  notification,
  Empty,
  Space,
  Badge,
} from 'antd';
import {
  ClockCircleOutlined,
} from '@ant-design/icons';

import { useLocation, useNavigate } from 'react-router-dom';
import Tree from 'rc-tree';
import {
    Node,
    NodeType,
    NodeStatus,
    useGetTree,
    TreeItem,
    ProjectNode,
    useListMeter,
} from '../services/projectApi';
import {
  useAuth,
  useDashboard,
  useMap,
  useComparison,
} from '../Providers';
import {
  Arrow,
  MeterOnlineIndicator,
  MeterOfflineIndicator,
  BuildingIcon,
  } from '../icons';
import Segmented from './Segmented';
import SearchInput from './SearchInput';

import { DataNode, EventDataNode, Key } from 'rc-tree/lib/interface';

import "rc-tree/assets/index.css";
import "./ProjectTree.css";
import moment from 'moment';
import { formatDurtionStr } from '../utils/converter';
import { hasFloorPlan } from '../utils/floorplan';


const ProjectTree: React.FC = () => {

    const [targetKey, setTargetKey] = useState('');
    const resolvRef = useRef<() => void>();
    const rejectRef = useRef<() => void>();
    const auth = useAuth();
    const dashboard = useDashboard();
    const map = useMap();
    const comparison = useComparison();
    let navigate = useNavigate();
    let location = useLocation();

    const {
      data: treeNodes,
      isFetching: isTreeFetching,
      isLoading: isTreeLoading,
      isSuccess: isTreeSuccess,
      error: treeError,
    } = useGetTree();

    const [treeData, setTreeData] = useState<DataNode[]>([]);
    const [filteredTreeData, setFilteredTreeData] = useState<DataNode[]>([]);
    const [expandedKeys, setExpandedKeys] = useState<Array<string> | undefined>(undefined);

    let param: object = {
      accountId: auth.account?.accountid,
    };

    const [filterMeterStatus, setFilterMeterStatus] = useState<NodeStatus>();
    const [searchText, setSearchText] = useState('');
    if (filterMeterStatus !== undefined) {
      param = {
        ...param,
        onlineStatus: filterMeterStatus,
      }
    }

    if (searchText) {
      param = {
        ...param,
        meterNo: searchText,
      }
    }

    const {
      data: nodes,
      isFetching,
      isLoading,
      isSuccess,
      error,
      refetch,
    } = useListMeter(param, { skip: isTreeFetching || !isTreeSuccess || treeData.length === 0 });

    const [nodeDict, setNodeDict] = useState<{ [key: string]: Node }>({});

    const getAllKeys = (node: DataNode): Array<string> => {
      const [objId, objType] = (node.key as string).split('-');
      if (parseInt(objType) === NodeType.StationArea) {
        if (node.children && node.children.length > 0) {
          return [node.key as string];
        }
        else {
          return [];
        }
      }

      const keys = node.children?.flatMap(c => getAllKeys(c)) ?? [];
      return keys ? [node.key as string, ...keys] : [];
    }

    function getName(obj: any): string {
      if (typeof obj === 'string') {
        return obj;
      }
      return Array.isArray(obj.props.children) ? getName(obj.props.children[0]) : getName(obj.props.children)
    }

    const onSelect = (selectedKeys: Key[], info: any) => {
        if (selectedKeys.length === 0) {
          return
        }
        console.log(selectedKeys[0])
        const [objid, objtype] = (selectedKeys[0] as string).split('-');
        let targetNode: any = {
          objid: parseInt(objid),
          objtype: parseInt(objtype),
          meterNo: info.selectedNodes[0]?.title(),
        };

        if (location.pathname === '/app/maps') {
          if (hasFloorPlan(targetNode)) {
            navigate('/app/floors', { state: { name: getName(info.selectedNodes[0]?.title()) } })
          }
        }

        if (targetNode.objtype === NodeType.Meter) {
          const node =  nodeDict[selectedKeys[0]];
          const meterNo = node.disc;
          if (location.pathname === '/app/maps') {
            map.setMeterId(node.objid);

          } else if (location.pathname === '/app/dashboard' || location.pathname === '/app/meter') {
            navigate('/app/meter', { state: { meterId: targetNode.objid, dcuId: node.dcuId, meterNo } })
          }
        }
        else if (location.pathname === '/app/meter') {
          navigate('/app/dashboard');
        }
        else if (location.pathname === '/app/comparison') {
          if (comparison.nodes.length >= 5) {
            notification['error']({
              message: 'Cannot Compare Node',
              description:
                'Reach maximum number of node, 5',
            });
            return;
          }

          const firstComparator = comparison.nodes.at(0);
          if (firstComparator && firstComparator.objtype !== targetNode.objtype) {
            notification['error']({
              message: 'Cannot Compare Node',
              description: 'Different Node Type',
            });
            return;
          }
          if ( targetNode.objtype === NodeType.PowerSupply) {
            notification['error']({
              message: 'Cannot Compare Node',
              description: 'Not Support for This Level',
            });
            return;
          }
          const obj = info.node.title();
          let name = '';
          if (typeof obj === 'string') {
            name = obj;
          }
          else {
            name = obj.props.children[0].props.children;
          }
          comparison.addNode(targetNode.objid, targetNode.objtype, name);
        }

        if (location.pathname === '/app/customer') {
          targetNode.meterNo = getName(info.selectedNodes[0]?.title())
        }
        dashboard.setTarget(targetNode);

        if (targetNode.objtype === NodeType.StationArea) {
          dashboard.setStationAreaIds([targetNode.objid]);
        }
        else if (treeNodes && targetNode.objtype !== NodeType.DCU) {
          const stationAreas = treeNodes.flatMap(node => getStation(node, targetNode.objid));
          dashboard.setStationAreaIds(stationAreas.map(sa => sa.value.objid));
        } else {
          dashboard.setStationAreaIds([]);
        }
    };

    const onLoadData =  async (treeNode: EventDataNode) => {
      const [objid, objtype]  = (treeNode.key as string).split('-');
      const objectType = parseInt(objtype);
      if (objectType !==  NodeType.StationArea && objectType !== NodeType.DCU && objectType !== NodeType.Meter) {
        return;
      }

      return new Promise<void>((resolve, reject) => {
        resolvRef.current = resolve;
        rejectRef.current = reject;
        setTargetKey(`${treeNode.key}`);
      });
    }
  
    function updateTreeData(list: DataNode[], key: React.Key, children: DataNode[]): DataNode[] {
      return list.map(node => {
        if (node.key === key) {
          return {
            ...node,
            children,
          };
        }
        if (node.children) {
          return {
            ...node,
            children: updateTreeData(node.children, key, children),
          };
        }
        return node;
      });
    }

    function sortTreeData(list: TreeItem<ProjectNode>[]): TreeItem<ProjectNode>[] {
      return list.map(node => {
        if (node.children && node.children.length > 1) {
          return {
            ...node,
            children: sortTreeData([...node.children].sort((a, b) => {
              return a.text.localeCompare(b.text)
            }
              )),
          };
        }
        return node;
      });
    }

    function filterOnlyChildrenExistTreeData(list: DataNode[]): DataNode[] {
      return list.reduce<DataNode[]>((acc, node) => {
        if (node.isLeaf && node.className === 'Meter') {
          return [
            ...acc,
            node
          ];
        }

        const nc = filterOnlyChildrenExistTreeData(node.children ?? []);
        if (nc.length > 0) {
          return [
            ...acc,
            {
              ...node,
              children: nc
            }
          ];
        }
        else {
          return acc;
        }
      }, []);
    }

    const mapNode = (node: Node) => {
      let url = '';
      switch (node.objtype) {
        case NodeType.Meter:
          url = "https://apps.smartttc.com/assets//img/emeter3.png";
          break;
        case NodeType.User:
          url = "https://apps.smartttc.com/assets//img/customer_group.png";
          break;
      }

      const renderTitle = (node: Node) => {
        if (node.objtype === NodeType.DCU || node.objtype === NodeType.Meter) {
          // const duration = new Date().valueOf() - new Date((node as any).onlineTime).valueOf();
          let diff = moment().diff(moment((node as any).onlineTime));
          // let duration = moment.utc(diff).format("HH [h] mm [m]");
          const duration = formatDurtionStr(diff, false);
          return (
            <>
            <Space size='small' direction='vertical' className="tree-text-container">
              <span>{node.disc}</span>
              {node.curstatus === NodeStatus.Offline ?
              // <Tag icon={<ClockCircleOutlined></ClockCircleOutlined>} color="#D1CABC">{duration}</Tag> : null}
              <span className="caption"><ClockCircleOutlined/> {duration === 'Invalid date' ? 'Unknown' : duration}</span> : null}
              </Space>
              <i>{
                node.curstatus === NodeStatus.Online
                ? <MeterOnlineIndicator />
                : <MeterOfflineIndicator />}
              </i>
            </>
          )
        }
        return node.disc;
      }

      return {
        title: () => renderTitle(node),
        key: `${node.objid}-${node.objtype}`,
        icon: <img src={url} alt={'icon'}/>,
        className: NodeType[node.objtype],
        isLeaf: node.objtype ===  NodeType.Meter || node.objtype === NodeType.User,
      }
    }

    const mapNode2 = (item: TreeItem<ProjectNode>): DataNode => {
      const { value: node } = item;
      let url = '';
      switch (node.objtype) {
        case NodeType.Meter:
          url = "https://apps.smartttc.com/assets//img/emeter3.png";
          break;
        case NodeType.User:
          url = "https://apps.smartttc.com/assets//img/customer_group.png";
          break;
      }

      const renderTitle = (node: ProjectNode) => {
        if (node.objtype === NodeType.DCU || node.objtype === NodeType.Meter) {
          return (
            <>
              <span>{node.disc}</span>
              <i>{
                node.curstatus === NodeStatus.Online
                ? <MeterOnlineIndicator />
                : <MeterOfflineIndicator />}
              </i>
            </>
          )
        }
        else if (node.objtype === NodeType.StationArea) {
          console.log(node.disc, node.objid)
          return (
            <>
            <span>{node.disc}</span>
            {hasFloorPlan(node) ? <BuildingIcon color="#FF8833" size={16} /> : null}
            <Tag color="#FFC36670">{node.subcount}</Tag>
            </>
          )
        }
        return node.disc;
      }

      return {
        title: () => renderTitle(node),
        key: `${node.objid}-${node.objtype}`,
        icon: <img src={url} />,
        className: NodeType[node.objtype],
        isLeaf: node.objtype ===  NodeType.Meter || node.objtype === NodeType.User,
        children: item.children ? item.children.map(mapNode2) : [],
      }
    }

    const switcherIcon = (obj: any) => {
        if (obj.isLeaf) {
          return false;
        }
        return <Arrow size={10}/>
      };

    useEffect(() => {
      if (isSuccess && !isLoading && !isFetching && nodes) {
        let newTreeData: DataNode[] = [];
        if (treeData.length === 0) {
          if (nodes.length > 0 && nodes[0].objtype === NodeType.SubBureau) {
           setTargetKey(`${nodes[0].objid}-${nodes[0].objtype}`);
          } else {
            newTreeData = nodes.filter(x => x.objtype !== NodeType.User).map(mapNode);
          }
        } else {
          if (targetKey) {
            newTreeData = updateTreeData(treeData, targetKey, nodes.filter(x => x.objtype !== NodeType.User).map(mapNode));
          } else {

            const meterByStationArea = nodes.filter(x => x.objtype !== NodeType.User)
              .reduce((acc, current) => {
                const key: string = `${current.parentId ?? 0}-${NodeType.StationArea}`;
                return {
                  ...acc,
                  [key]: [
                    ...(acc[key] || []),
                    current,
                  ]
                }
              }, {} as any);

            newTreeData = [ ...treeData ];
            Object.keys(meterByStationArea).forEach(key => {
              newTreeData = updateTreeData(newTreeData, key, meterByStationArea[key].map(mapNode));
            });

            newTreeData = filterOnlyChildrenExistTreeData(newTreeData);
          }
        }
        setFilteredTreeData(newTreeData);

        const keys = searchText ? newTreeData.map(c => getAllKeys(c)).flatMap(a => a) : undefined;
        setExpandedKeys(keys);
        const dict = nodes.reduce((acc, cur) => ({ ...acc, [`${cur.objid}-${cur.objtype}`]: cur}), nodeDict)
        setNodeDict(dict);
        map.setNodeMapper(dict);
        if (resolvRef.current) {
          resolvRef.current();
        }
      } else if (error) {
        if (rejectRef.current) {
          rejectRef.current();
        }
      }
    }, [isSuccess, isLoading, nodes, error]);

    const filterNode = (list: DataNode[], status: NodeStatus | undefined, searchText = ''): DataNode[] => {
      if (status === undefined && !searchText) {
        return list;
      }
      
      return list.map(node => {
        const obj = nodeDict[node.key];
        if (obj && obj.objtype === NodeType.StationArea && node.children) {
          return {
            ...node,
            children: node.children.filter(c => {
              const cObj = nodeDict[c.key];
              const isStatusMatched = status === undefined ? true : cObj.curstatus === status;
              return searchText ? isStatusMatched && cObj.disc.search(searchText) !== -1 : isStatusMatched;
            }),
          };
        }

        if (node.children) {
          return {
            ...node,
            children: filterNode(node.children, status, searchText),
          };
        }
        return node;
      });
    }

    useEffect(() => {
      if (isTreeSuccess && !isTreeLoading && !isTreeFetching && treeNodes) {
        const powerlineNodes = treeNodes.flatMap(node => getProjects(node))//.filter(k => ['ARMY'].includes(k.text));
        const sortedPowerlineNodes = sortTreeData(powerlineNodes)
        const newTreeData = sortedPowerlineNodes.map(mapNode2);
        setTreeData(newTreeData);

        if (resolvRef.current) {
          resolvRef.current();
        }
      }
      else if (treeError) {
        if (rejectRef.current) {
          rejectRef.current();
        }
      }
    }, [isTreeSuccess, isTreeLoading, treeNodes, treeError]);

    const getProjects = (node: TreeItem<ProjectNode>): TreeItem<ProjectNode>[] => {
      if (node.value.objtype === NodeType.SubBureau) {
        return node.children;
      }
      return node.children.flatMap(n => getProjects(n))
    }

    const getStation = (node: TreeItem<ProjectNode>, parentId: number, collect=false): TreeItem<ProjectNode>[] => {
      if (node.value.objtype === NodeType.StationArea) {
        return collect ? [node] : [];
      }
      return node.children.flatMap(n => getStation(n, parentId, collect || node.value.objid === parentId))
    }

    let attrs = {};
    if (expandedKeys) {
      attrs = {
        ...attrs,
        expandedKeys,
      }
    }

    return (
      <>
        <SearchInput
          loading={isFetching}
          onChange={text => setSearchText(text)}
          onRefresh={() => refetch()}
        />
        <Segmented
          options={['ALL', 'ONLINE', 'OFFLINE']}
          onChange={(index: number) => {
            let status = undefined;
            switch (index) {
              case 0:
                status = undefined;
                break;
              case 1:
                status = NodeStatus.Online;
                break;
              case 2:
                status = NodeStatus.Offline;
                break;
            }
            setFilterMeterStatus(status);
          }}/>
        {
        treeNodes === undefined && isTreeFetching
        ? <Spin spinning={true} />
        : (
          filteredTreeData.length > 0
          ? <Tree
              switcherIcon={switcherIcon}
              onSelect={onSelect}
              treeData={filteredTreeData}
              virtual={true}
              showIcon={true}
              checkable={false}
              { ...attrs } />
          : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Meter NOT found"/>)
        }
      </>
    );
}

export default ProjectTree;
