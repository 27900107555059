import {
    baseApi,
    Response,
    ObjectResponse,
    Pagination,
    ListResponse,
    ListResponseWithTotal,
} from './baseApi';
import { translate } from '../utils/translate';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'

export enum NodeType {
    Bureau = 30,
    SubBureau = 31,
    PowerSupply = 32,
    Line = 33,
    StationArea = 34,
    User = 35,
    DCU = 1,
    Meter = 10,
}

export enum EventLevel {
    Critical = 1,
    Warning = 2,
    Info = 3,
}

export enum AlarmEventType {
    All = 0,
    Standard = 1,
    Fraud = 2,
    Control = 3,
    PowerQuality = 4,
    Token = 5,
    MeasurementLog = 6,
    DCUParameters = 7,
    Master = 8,
}

export interface MeterEvent {
    readonly meterno: string;
    readonly pointid: number;
    readonly eventitemid: number;
    readonly eventdisc: string;
    readonly eventStartTime: Date;
    readonly eventEndTime: Date;
    readonly reportTime: Date;
    readonly duration: number;
    readonly level: EventLevel;
    readonly alarmEventType: AlarmEventType
    readonly isNew: boolean;
}

export interface MeterEventRaw {
    readonly meterno: string;
    readonly pointid: number;
    readonly eventitemid: number;
    readonly eventdisc: string;
    readonly eventStartTime: string;
    readonly eventEndTime: string;
    readonly reportTime: string;
    readonly duration: number;
    readonly level: EventLevel;
    readonly alarmEventType: AlarmEventType
}

export interface OfflineLog {
    readonly liveTime: number;
    readonly onlineTime: number;
    readonly duration: number;
}

export interface LiveLog {
    readonly livetime: number;
    readonly onlinetime: number;
}

const prefix = '/smartami/EventService';

export const eventApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        listEvent: build.query<MeterEventRaw[], { accountId?: string, objectId?: number, objectType?: number }>({
            query: (params = {}) => {
                return {
                    url:  `${prefix}/queryEvent`,
                    method: 'POST',
                    body: {
                    },
                }
            },
            transformResponse: (response: Response<ObjectResponse<MeterEventRaw>>, meta, arg) => response.data.item.map(item => ({ ...item, eventdisc: translate(item.eventdisc) })),
        }),
        listMeterEvent: build.query<Pagination<MeterEventRaw>, { meterId: number, eventType: number, start: string, end: string, page: number }>({
            query: (params) => {
                return {
                    url:  `${prefix}/meterEvent`,
                    method: 'GET',
                    params,
                }
            },
            transformResponse: (response: Response<Pagination<MeterEventRaw>>, meta, arg) => {
                const objs = response.data.objs.map(item => ({ ...item, eventdisc: translate(item.eventdisc) }))
                return {
                    ...response.data,
                    objs,
                }
            },
        }),
        listEventDict: build.query({
            queryFn: async (params, queryApi, _extraOptions, fetchWithBQ) => {
                try {
                    const resp = await fetch('/en.json');
                    return { data: resp.json() };
                } catch (error) {
                    return {
                        error: error as FetchBaseQueryError
                    };
                }
            },
        }),
        listLiveMeterEvent: build.query<Pagination<OfflineLog>, { meterId: number, page: number, pageSize: number }>({
            query: (params) => {
                return {
                    url:  `/smartami/MeterDataService/getMeterLiveLogList`,
                    method: 'POST',
                    body: {
                        objid: params.meterId,
                        objtype: 10,
                        pageNum: params.page,
                        pageSize: params.pageSize,
                    },
                }
            },
            transformResponse: (response: Response<ListResponseWithTotal<LiveLog>>, meta, arg) => {
                const objs = response.data.listitem.map(item => ({
                    liveTime: item.livetime,
                    onlineTime: item.onlinetime,
                    duration: item.onlinetime - item.livetime,
                }))
                return {
                    objs,
                    total: response.data.total,
                }
            },
        }),
    }),
    overrideExisting: false,
});

export const {
    useListEventQuery: useListEvent,
    useListMeterEventQuery: useListMeterEvent,
    useListEventDictQuery: useListEventDict,
    useListLiveMeterEventQuery: useListLiveMeterEvent,
} = eventApi;
